
div[class=" css-tlfecz-indicatorContainer"] {
    color: #444053;
    padding: 0;
    margin-right: 24px;
    width: 15px;
    transition: ease transform 0.3s, ease color 0.2s;
}
div[class=" css-tlfecz-indicatorContainer"]:first-child:hover {
    color: #DE350B;
    padding: 0;
    margin-right: 24px;
    width: 15px;
    transition: ease color 0.2s;
}
div[class=" css-1gtu0rj-indicatorContainer"] {
    color: #444053;
    padding: 0;
    margin-right: 24px;
    width: 15px;
}
div[class=" css-1gtu0rj-indicatorContainer"]:last-child {
    color: #444053;
    padding: 0;
    margin-right: 24px;
    width: 15px;
    transition: ease transform 0.3s;
    transform: rotate(180deg);
}

.css-g1d714-ValueContainer {
    min-height: 45px !important;
    padding-left: 24px !important;
    overflow: auto !important;
}
.css-g1d714-ValueContainer::-webkit-scrollbar {
    width: 3px;
    background: transparent;
}
.css-g1d714-ValueContainer::-webkit-scrollbar-thumb {
    background: #cecece;
}
.css-g1d714-ValueContainer:first-child {
    height: 45px;
    margin-top: -2px;
    padding-left: 24px !important;
}
.css-1okebmr-indicatorSeparator {
    display: none;
}
/* Input */
div[class=" css-yk16xz-control"] {
    border: 1px solid #E5E5E5 !important;
    height: 45px;
    outline: none !important;
}
div[class=" css-1pahdxg-control"] {
    height: 52px;
    border: 1px solid #124A6B !important;
    box-shadow: none;
}
div[class=" css-1wa3eu0-placeholder"] {
    font-size: 1.125rem;
    font-weight: 400;
    opacity: 0.7;
    margin-top: -1px;
}
/* Selected Item */
.css-1rhbuit-multiValue {
    background-color: unset !important;
}
.css-1o9scq-multiValue {
    border: none !important;
}
.css-12jo7m5 {
    background: #124A6B;
    padding: 4px 4px 4px 24px !important;
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    letter-spacing: -0.25px !important;
    color: #FFFFFF !important;
}
/* Selected Item Icon */
.css-xb97g8 {
    background: #124A6B;
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
    color: white;
    padding-right: 8px !important;
    transition: color 0.2s;
}
.css-xb97g8:hover {
    background: #124A6B !important;
    transition: color 0.2s;
}

/* Menu & MenuList*/
div[class=" css-26l3qy-menu"] {
    box-shadow: none;
    background-color: #FFFFFF;
    margin-top: -4px;
    transition: all 1s;
    box-shadow: none !important;
    border: 1px solid #124A6B !important;
    border-top: none !important;
    border-radius: 0;
}
div[class=" css-3w4z63-MenuList"] {
    border: none !important;
    box-shadow: none !important;
}
div[class=" css-3w4z63-MenuList"]::-webkit-scrollbar {
    width: 3px;
    background: transparent;
    border-radius: 0 8px 8px 0;
}
div[class=" css-3w4z63-MenuList"]::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 0 8px 8px 0;
}
div[class=" css-cpv7fw-MenuList"]::-webkit-scrollbar {
    width: 3px;
    background: transparent;
    border-radius: 0 8px 8px 0;
}
div[class=" css-cpv7fw-MenuList"]::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 0 8px 8px 0;
}

.css-4ljt47-MenuList {
    border: none !important;
    box-shadow: none !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

/* Selected Option */
div[class=" css-1q5lh8f-option"] {
    padding: 12px 24px 12px 16px;
    font-size: 18px !important;
    font-weight: 400;
    background: rgba(229,229,229,0.1);
    opacity: 1;
    cursor: pointer;
}
div[class=" css-1q5lh8f-option"]:hover {
    font-size: 18px !important;
    font-weight: 400;
    background: rgba(229,229,229,0.1);
    opacity: 1;
}
/* Other Options */
div[class=" css-mp56uh-option"] {
    padding: 12px 24px 12px 16px;
    font-size: 18px;
    font-weight: 400;
    background: rgba(229,229,229,0.1);
    opacity: 1;
    cursor: pointer;
}