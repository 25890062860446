.react-calendar {
    min-width: 250px;
    max-width: 250px;
    background: white;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: 5.93126px;
    background: #4285F4;
    box-shadow: 0px 1.97709px 15.8167px rgba(170, 170, 170, 0.03);
    z-index: 1;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar {
    color: #FFFFFF;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 30px;
  }
  .react-calendar__navigation button {
    min-width: 30px;
    background: none;
    color: #FFFFFF;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: rgba(0, 0, 0, 0.038);
  }
  .react-calendar__navigation button[disabled] {
    opacity: 0;
    cursor: default;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    font-weight: 600;
    font-size: 11.8625px;
    line-height: 14px;
    color: #FFFFFF;
    text-decoration: none;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day abbr {
    color: #FFFFFF;
    line-height: 14px;
    font-size: 11.8625px;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth abbr {
    opacity: 0.5;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    color: #FFFFFF;
    border-radius: 0;
  }
  .react-calendar__century-view .react-calendar__tile {
    line-height: 16px;
  }
  .react-calendar__tile.react-calendar__year-view__months__month:hover abbr,
  .react-calendar__tile.react-calendar__decade-view__years__year:hover {
    color: #ffffffc0;
    font-weight: 600;
    transition: ease font-weight 0.1s, ease color 0.1s;
  }
  .react-calendar__tile.react-calendar__century-view__decades__decade:hover {
    color: #ffffffc0;
    transition: ease color 0.1s;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 9999px;
    transition: ease background-color 0.2s;
  }
  .react-calendar__tile.react-calendar__year-view__months__month:hover,
  .react-calendar__tile.react-calendar__decade-view__years__year:hover,
  .react-calendar__tile.react-calendar__century-view__decades__decade:hover  {
    background-color: unset;
  }
  .react-calendar__tile--now {
    border-radius: 9999px;
  }
  .react-calendar__tile--now.react-calendar__year-view__months__month abbr {
    color: #FFFFFF;
  }
  .react-calendar__tile.react-calendar__year-view__months__month abbr {
    color: #FFFFFF;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 9999px;
  }
  .react-calendar__tile--now.react-calendar__year-view__months__month:hover,
  .react-calendar__tile--now.react-calendar__decade-view__years__year:hover,
  .react-calendar__tile--now.react-calendar__century-view__decades__decade:hover {
    background-color: unset;
  }
  .react-calendar__tile--hasActive {
    background-color: #FFFFFF;
  }
  .react-calendar__tile--hasActive.react-calendar__month-view__days__day abbr {
    color: #4285F4;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #FFFFFF;
    border-radius: 9999px;
  }
  .react-calendar__tile--hasActive.react-calendar__decade-view__years__year,
  .react-calendar__tile--hasActive.react-calendar__year-view__months__month,
  .react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
    background-color: unset;
  }
  .react-calendar__tile--hasActive.react-calendar__decade-view__years__year:hover,
  .react-calendar__tile--hasActive.react-calendar__year-view__months__month:hover,
  .react-calendar__tile--hasActive.react-calendar__century-view__decades__decade:hover {
    background-color: unset;
  }
  .react-calendar__tile--active {
    background: #FFFFFF;
    border-radius: 9999px;
    color: white;
  }
  .react-calendar__tile--active.react-calendar__month-view__days__day abbr {
    color: #4285F4;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #FFFFFF;
  }
  .react-calendar__tile--active.react-calendar__year-view__months__month:hover,
  .react-calendar__tile--active.react-calendar__decade-view__years__year:hover,
  .react-calendar__tile--active.react-calendar__century-view__decades__decade:hover {
    background-color: unset;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #FFFFFF;
  }
  